require("./assets/styles/index.less")

const faceapi = require('./assets/face-api/face-api.js');

const errorMap = {
  'NotAllowedError': '摄像头已被禁用，请在当前浏览器设置中开启后重试',
  'AbortError': '硬件问题，导致无法访问摄像头',
  'NotFoundError': '未检测到可用摄像头',
  'NotReadableError': '操作系统上某个硬件、浏览器或者网页层面发生错误，导致无法访问摄像头',
  'OverConstrainedError': '未检测到可用摄像头',
  'SecurityError': '摄像头已被禁用，请在系统设置或者浏览器设置中开启后重试',
  'TypeError': '类型错误，未检测到可用摄像头'
};
class FaceDetection {
  constructor(options) {
    this.options = Object.assign({
      matchedScore: 0.9,
      mediaSize: {
        width: 250,
        height: 250
      }
    }, options);
    this.timer = null;
    this.mediaStreamTrack = null; // 摄像头媒体流
    this.actions = [
      { name: '请眨眼', action: (landmarks) => this.isEyesClosed(landmarks) },
      { name: '请抬头', action: (landmarks) => this.isHeadUp(landmarks) },
      { name: '请张嘴', action: (landmarks) => this.isMouthOpen(landmarks) },
      { name: '请向左看', action: (landmarks) => this.checkLookLeft(landmarks) },
      { name: '请向右看', action: (landmarks) => this.checkLookRight(landmarks) },
    ];
    this.currentActionIndex = 0;
    this.showConfirmBtn = false
    this.videoEl = document.querySelector('#videoEl'); // 视频区域
    this.container = document.getElementById('container');
    this.canvasImgEl = document.querySelector('#canvasImg'); // 图片绘制区域
    this.operationEl = document.querySelector('.operation'); // 操作按钮
    this.retryBtnEl = document.querySelector('.js-retry');
    this.compareBtnEl = document.querySelector('.js-compare');
    this.text = document.getElementById('text');
    this.tiptext= document.getElementById('tiptext');
    this.wrapperEl =  document.getElementById("video-wrapper")
    this.animationId = null;
    this.leftLook = null;
    this.rightLook = null;
    this.params=null;
    this.init();
    document.getElementById("start").addEventListener("click", () => {
      this.startEvent()
      document.getElementById("btn-container").style.display = 'none'
      document.getElementById("wraper").style.display = 'block'
    })
  }
  async init() {
    await faceapi.loadFaceLandmarkModel('https://face.xczx089.com/assets/face-api/models');
    await faceapi.loadTinyFaceDetectorModel('https://face.xczx089.com/assets/face-api/models');
    let userAgent = navigator.userAgent 
    if (userAgent.match(/iPhone/i)) {
       this.wrapperEl.style.width="350px"
       this.wrapperEl.style.height="350px"
        this.videoEl.style.objectFit ='fill'
     } else {
      this.wrapperEl.style.width="250px"
     this.wrapperEl.style.height="250px"
       this.videoEl.style.objectFit ='cover'
     }
     document.getElementById("toptitle").innerHTML="请摘下眼镜，保证五官清晰可见，避免背光。"
    this.initDetection();
    // this.bindEvents();
  }
  // 设置相关容器大小
  // 初始化人脸识别
  async initDetection() {
    // 加载模型
    // 获取 WebRTC 媒体视频流
    let hasError = false
    let stream = null
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      // 最新标准API
      stream = await navigator.mediaDevices.getUserMedia({ video: true})
        .catch((error)=>{
          console.log(error)
          hasError = true;   // 发生错误时设置为 true
          this.mediaErrorCallback(error);  // 调用错误回调
        });
    } else if (navigator.webkitGetUserMedia) {
      // webkit内核浏览器
      stream = await navigator.webkitGetUserMedia({ video: true})
       .catch((error)=>{
          hasError = true;   // 发生错误时设置为 true
          this.mediaErrorCallback(error);  // 调用错误回调
        });
    } else if (navigator.mozGetUserMedia) {
      // Firefox浏览器
      stream = await navigator.mozGetUserMedia({ video: true})
        .catch((error)=>{
          hasError = true;   // 发生错误时设置为 true
          this.mediaErrorCallback(error);  // 调用错误回调
        });
    } else if (navigator.getUserMedia) {
      // 旧版API
      stream = await navigator.getUserMedia({ video: true})
      .catch((error)=>{
        hasError = true;   // 发生错误时设置为 true
        this.mediaErrorCallback(error);  // 调用错误回调
      });
    }


     
    if(hasError){
        return false
    }
    this.videoEl.srcObject = stream

    window.parent.postMessage({ state: 'ready' }, '*')
    await  this.windowlinseEvent()
    if (!this.showConfirmBtn) {
      this.startEvent();
     }
    //等video加载完成
  }
  decryptedEvent(encryptedObj) {  
    const { encryptedKey, encryptedData } = encryptedObj;  
    // 使用JSEncrypt库和RSA私钥解密AES密钥  
    let decrypt = new JSEncrypt();  
    // 替换为你的RSA私钥  
    const privateKey = `-----BEGIN RSA PRIVATE KEY-----
  MIICWwIBAAKBgQDGFD4nNGxk9w4iIK3V+XX9MnvfdW8enlS2eVUe4kucHbhFamR3
  00+3vLdsWopemB1jKgEJ+Q+ewwhSGOOeZTbOUi2+u2ajbWkvaQY2OBUz4vLnwELu
  zEBHsLVTtv3YhaZEEWf1CvjAORIryi4TSND6tpIbM/UN1J+2xqDY5QahYwIDAQAB
  AoGAAS6ov6TSoCL7XMfI0dX3LCeLfBDK339pbly4syz9mjUb3vTcxvxQuv4viBWI
  QuVHM6AnY8RZTATKUsHJm1iWTvqUjQmlhEj/e4RZ4asOYk56QZmRufkCxoo85Qxb
  C2zT2Ct5Lwrpaj3XkGXaamKHBM/T/nGMBre6vn8/d3K+5lECQQD5OSxxAKAtEd+v
  u9HiP2OyAL4Eu8/zqkgohUSlvYfhe54T3tjiC2yEct90cYj7Ak+6XbpIoinhgO+K
  Dt0Y8WdRAkEAy3cOubqXQc1SO3vmSO4eKWDu1kGAv+GpyjiY/jYYqQH3CyghqQJj
  IpaiH1IlHogXnYuR+fWtBsdeo3PAGmO4cwJASg41KHb5IatX/o4e9c3EQBZ8YEe2
  qT8GJhRDq8Q/5j6nYFwtsi2W11yTDbTW8U9khYpoWkDllP6NL0bvaAQDoQJADf+h
  /rtMvtU+jCMpI+lmH9iwdgNJtWn0QZqFRDA6oB4nfhgDwNoOMgoFG3yOWzxny4oX
  wAfcILRE2aAUHnyv6wJAGV1fKxMcoStSuLhvYZ4pqRepEP0N30IzQEyuBRiFQb78
  HBCTRX98O+6/2mCh5a2AaE8XPkWmeDyFveA8JWA7HA==
  -----END RSA PRIVATE KEY-----
  `;  
    decrypt.setPrivateKey(privateKey);  
    let decryptedAesKey = decrypt.decrypt(encryptedKey);  
    // 将解密后的AES密钥转换为CryptoJS可以使用的格式  
    const aesKey = CryptoJS.enc.Utf8.parse(decryptedAesKey);  
    // 使用AES解密数据  
    const bytes = CryptoJS.AES.decrypt(encryptedData, aesKey, {  
        mode: CryptoJS.mode.ECB,  
        padding: CryptoJS.pad.Pkcs7  
    });  
    // 将解密后的数据转换为JSON对象  
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));  
    return decryptedData;  
}
  async windowlinseEvent() {
    return new Promise((resolve, reject) => {
      let arr = []
      if(location.href.indexOf("encryptedKey")!=-1&&location.href.indexOf("encryptedData")!=-1){
        let str = location.href.slice(location.href.indexOf("encryptedKey"))
        let obj = {
          encryptedKey: str.slice(13,str.indexOf("&encryptedData")),
          encryptedData: str.slice(str.indexOf("encryptedData")+14) 
        }
        this.params= this.decryptedEvent(obj)
        this.params.postType.forEach(res => {
          arr.push(this.actions[res])
        })
        if (this.params.pageType == 1) {
          this.showConfirmBtn = true
          document.getElementById('btn-container').style.display = 'block'
          document.getElementById('start').innerHTML = '开始检测'
        } else {
          this.showConfirmBtn = false
          document.getElementById('wraper').style.display = 'block'
        }
        this.actions = arr
        resolve();
        }else{
          window.addEventListener('message', (event) => {
          console.log(event.data)
            if (event.data.postType) {
              this.params=event.data
              //  if(urlList.indexOf(event.origin)!=-1){
              event.data.postType.forEach(res => {
                arr.push(this.actions[res])
              })
              if (event.data.pageType == 1) {
                this.showConfirmBtn = true
                document.getElementById('btn-container').style.display = 'block'
                document.getElementById('start').innerHTML = '开始检测'
        
              } else {
                this.showConfirmBtn = false
                document.getElementById('wraper').style.display = 'block'
              }
              this.actions = arr
            }
        resolve();
          });
        }
    })
  }
  startEvent() {
      document.getElementById("over").style.display = 'block';
      const url = "https://face.xczx089.com/v3/api/pd/prodService/faceOne"
      const submitData = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', 
        },
        body: JSON.stringify({
          member_id: this.params.member_id, 
          accessoryUrl: "https://www.baidu.com/s?ie=UTF-8&wd=baidu",
          timestamp: this.params.timestamp,
          trans_id: this.params.trans_id,
          notFee:  "kfone",
          data:this.params.aesRes
        })
      }
       fetch(url, submitData, 'callback').then(response => response.json()).then(initRes=>{
        document.getElementById("over").style.display = 'none';
        if(initRes.code!=0){
          alert(initRes.msg)
          return false
        }

        this.videoEl.play()
        this.initVideo();
       }).catch(error => {
        alert(error)
      })
  }
  // 初始化视频流
  initVideo(stream) {
    // 动作检测：检测距离
      this.videoEl.onplay = () => {
        this.checkActions();
      };
  }

  // 获取媒体流错误处理
  mediaErrorCallback(error) {
    if (errorMap[error.name]) {
      alert(errorMap[error.name]);
    }
  }


  // 动作检测：检测距离
  checkDistance(detection) {
    const faceWidth = detection.box.width;  // 获取人脸框的宽度

    // 设置合适的距离范围，可以根据实际需求调整
    const minFaceWidth = 270;  // 用户太远
    const maxFaceWidth = 330;  // 用户太近
    // 如果人脸框宽度在合理范围内，表示用户在合适的距离
    if (faceWidth >= minFaceWidth && faceWidth <= maxFaceWidth) {
      this.text.innerHTML = "";
      return true;
    } else if (faceWidth < minFaceWidth) {
      this.text.innerHTML = "离摄像头太远，请靠近一些";
    } else if (faceWidth > maxFaceWidth) {
      this.text.innerHTML = "离摄像头太近，请远离一些";
    }
    return false;
  }
  isFacingFront(landmarks,name) {
    if(name!="请眨眼"||name!="请张嘴"){
      return true
  }
    const nose = landmarks.getNose();
    const leftEye = landmarks.getLeftEye();
    const rightEye = landmarks.getRightEye();
  
    // 计算两只眼睛的平均 x 坐标
    const averageEyeX = (leftEye[0].x + rightEye[3].x) / 2;
  
    // 如果鼻子的 x 坐标接近两眼之间的平均值，则表示人物正对摄像头
    const noseToCenterThreshold = 15; // 阈值可以根据实际情况调整

if(Math.abs(nose[0].x - averageEyeX) < noseToCenterThreshold){
  return true
}
document.querySelector('#tips').innerHTML = "请正对面向屏幕";
return false
  }
  
  // 判断当前动作是否完成
  async checkActions() {
    const tips = document.querySelector('#tips'); // 显示提示的元素
   document.getElementById("toptitle").style.display="none"
   tips.innerHTML=this.actions[0].name
    // setTimeout(()=>{
      const onPlay = async () => {
        if (this.videoEl.readyState === 4) {
          try {
            // 检测人脸
            const detections = await faceapi.detectSingleFace(this.videoEl, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks();
            // 检查是否检测到人脸
            if (detections && detections.detection) {
              const landmarks = detections.landmarks;
              const currentAction = this.actions[this.currentActionIndex];

              if(this.isFacingFront(detections.landmarks,currentAction.name)){
                
              if (this.checkDistance(detections.detection)) {
                // 获取当前需要完成的动作
                tips.innerHTML = currentAction.name;  // 提示当前动作名称
                // 检测当前动作是否完成
                  if (currentAction.action(landmarks)) {
                    // 跳到下一个动作
                    this.currentActionIndex++;
                    // 如果还有剩余的动作，提示下一个动作
                    if (this.currentActionIndex < this.actions.length) {
                        tips.innerHTML = this.actions[this.currentActionIndex].name;
                    } else {
                      tips.innerHTML = '请保持姿势不变';
                      // let loading = document.getElementById('#loading');
                      // loading.style.display = 'none';
                      this.takeSnapshot();
                      cancelAnimationFrame(this.animationId)
                      return;
                    }
                  }
              }
             }

            }
          } catch (error) {
            cancelAnimationFrame(this.animationId)
            console.error("检测过程中出错: ", error);
          }
        }
        this.animationId = requestAnimationFrame(onPlay); // 继续下一帧检测
      };
  
      // 开始播放视频
      // if (this.videoEl.paused || this.videoEl.ended) {
      //   this.videoEl.play();
      // }
      onPlay(); // 启动检测
    // },1000)
    // 

  }  // 人脸框绘制


  showEl(el) {
    el.style.visibility = 'visible';
    return this;
  }

  hideEl(el) {
    el.style.visibility = 'hidden';
    return this;
  }
  // 判断是否闭眼（通过检测眼睛的开合程度）
  isEyesClosed(landmarks) {
    const leftEye = landmarks.getLeftEye();
    const rightEye = landmarks.getRightEye();

    // 计算眼睛的开合比值，值越小，眼睛闭合越紧
    const eyeAspectRatio = (eye) => {
      const width = Math.hypot(eye[0].x - eye[3].x, eye[0].y - eye[3].y);
      const height = (Math.hypot(eye[1].x - eye[5].x, eye[1].y - eye[5].y) + Math.hypot(eye[2].x - eye[4].x, eye[2].y - eye[4].y)) / 2;
      return height / width;
    };
    const leftEAR = eyeAspectRatio(leftEye);
    const rightEAR = eyeAspectRatio(rightEye);
    // EAR 阈值，通常 < 0.25 表示眼睛闭合
    // this.text.innerHTML ="眨眼距离："+ (leftEAR + rightEAR) / 2
    return (leftEAR + rightEAR) / 2 < 0.290
  }

  // 判断是否张嘴
  isMouthOpen(landmarks) {
    const mouth = landmarks.getMouth();
    const mouthOpen = mouth[7].y - mouth[3].y;
    // 当竖直距离比水平方向大一定阈值时，表示嘴巴张开
    return mouthOpen > 30;  // 张嘴检测
  }

  // 判断头部抬起
  isHeadUp(landmarks) {
    let nose = landmarks.getNose();  // 获取鼻子关键点
    let leftEye = landmarks.getLeftEye();  // 获取左眼关键点
    let rightEye = landmarks.getRightEye();  // 获取右眼关键点
    // 计算两只眼睛的平均 y 坐标
    let averageEyeY = (leftEye[0].y + rightEye[3].y) / 2;
    // 判断鼻子 y 坐标是否高于眼睛的平均 y 坐标，表示抬头

    return nose[0].y < averageEyeY - 5;  // 根据需要调整这个阈值
  }
  // 动作检测：向左看
  checkLookLeft(landmarks) {
    const nose = landmarks.getNose();
    const leftEye = landmarks.getLeftEye();
    const rightEye = landmarks.getRightEye();

    // 鼻子相对于左眼位置应当偏右，表示用户向左看（视频翻转后）

    return rightEye[3].x - nose[0].x < 40
  }

  // 动作检测：向右看
  checkLookRight(landmarks) {
    // const nose = landmarks.getNose();

    // const rightEye = landmarks.getRightEye();
    // this.text.innerHTML ='向右看:鼻子：'+ nose[0].x + '眼睛：'+rightEye[3].x;

    // // 鼻子相对于右眼位置应当偏左，表示用户向右看（视频翻转后）
    // return nose[0].x > rightEye[3].x;
    const nose = landmarks.getNose();
    const leftEye = landmarks.getLeftEye();
    const rightEye = landmarks.getRightEye();

    // 鼻子相对于左眼位置应当偏右，表示用户向左看（视频翻转后）
    return nose[0].x - leftEye[0].x < 40
  }

  takeSnapshot() {
    const colors = [
      'rgb(111, 145, 119)',
      'rgb(40, 187, 74)',
      'rgb(59, 37, 197)',
      'rgb(255, 204, 25)',
      'rgb(255, 255, 255)'
    ];
    let index = 0;
    const intervalId = setInterval(() => {
      // 设置背景色
      let textTip = document.getElementById('tips')
      textTip.style.color = "white"
      this.container.style.backgroundColor = colors[index];
      // 更新索引，每次循环到下一个颜色
      index++;
      // 如果所有颜色都展示完，清除定时器
      if (index >= colors.length) {
        clearInterval(intervalId);
        textTip.style.color = "black"
        //停止录制视频
        this.videoEl.pause();
        document.getElementById("over").style.display = 'block';
        this.canvasImgEl.getContext('2d').drawImage(this.videoEl, 0, 0, this.canvasImgEl.width, this.canvasImgEl.height);
        this.canvasImgEl.style.opacity = 0
        let image = this.canvasImgEl.toDataURL('image/png',0.8);
        image = image.replace('data:image/png;base64,', ''); // 去掉 base64 头部信息
        setTimeout(() => {     // 延时3秒
          document.getElementById("over").style.display = 'none';
        }, 3000);
        window.parent.postMessage({ data: image, state: 'complete' }, '*')
      }
    }, 500);

    // url.src = image;
    // 这里可以发送 image 到后台服务器进行验证
  }

}

module.exports = FaceDetection;